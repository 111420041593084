import { useState } from 'react';
import { ICenter } from './Centers';
import { Button, Dialog } from '@mui/material';
import {
    MainSection,
    TextHeader,
    TextSmall,
    CenterInfo,
    CentersContainer,
} from '../styles';
import { Delete } from '../centers/Delete';

interface IEditCenterProps {
    center: ICenter;
    action: string;
}

export default function EditCenterUI(props: IEditCenterProps) {
    const [open, setOpen] = useState(false);

    return (
        <MainSection>
            <CentersContainer>
                <CenterInfo>
                    <TextHeader>{props.center.name}</TextHeader>
                    <br />
                    <TextSmall className='typo'>
                        {props.center.address}
                        <br />
                        Contact:{' '}
                        <a
                            href={props.center.contactUrl}
                            style={{
                                textDecoration: 'none',
                                color: 'rgb(80, 104, 153)',
                            }}
                        >
                            {props.center.contactUrl}
                        </a>
                        <br />
                        Booking:{' '}
                        <a
                            href={props.center.bookingUrl}
                            style={{
                                textDecoration: 'none',
                                color: 'rgb(80, 104, 153)',
                            }}
                        >
                            {props.center.bookingUrl}
                        </a>
                        <br />
                    </TextSmall>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            style={{ marginRight: '1em' }}
                            variant='outlined'
                            type='button'
                            onClick={() => window.history.back()}
                        >
                            Back
                        </Button>
                        {props.action === 'EDIT' ? (
                            <Button
                                style={{ marginRight: '1em' }}
                                variant='outlined'
                                color='warning'
                                onClick={() => setOpen(true)}
                            >
                                Delete
                            </Button>
                        ) : null}

                        <Dialog open={open} onClose={() => setOpen(false)}>
                            <Delete handleOpen={() => setOpen(false)} />
                        </Dialog>
                    </div>
                </CenterInfo>
            </CentersContainer>
        </MainSection>
    );
}
