import { useSelector, useDispatch } from 'react-redux';
import { Key, useEffect, useCallback, useState } from 'react';
import {
    selectError,
    selectLoading,
    selectCenters,
} from './../../store/selectors';
import { ICenter } from '../centers/Centers';
import { ICity } from './Cities';
import { TextSmall, TextLink, CentersContainer, CenterCard } from '../styles';
import { setCenters } from '../../utils/centersFunctions';
import { useApi } from '../../utils/useApi';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

interface ICityCenterProps {
    cityInfo: ICity;
}

export default function CityCenters(props: ICityCenterProps) {
    const centersState = useSelector(selectCenters);
    const errorState = useSelector(selectError);
    const loadingState = useSelector(selectLoading);
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const api = useApi();
    type Param = { id: string };
    const { id } = useParams<Param>();

    let [displayCenters, setDisplayCenters] = useState<Boolean>(true);

    useEffect(() => {
        if (!centersState || centersState.length < 1) {
            setCenters(api, dispatchCallback);
            return;
        }
    }, [id, api, dispatchCallback, centersState]);

    useEffect(() => {
        let centers = centersState.filter(
            (center: ICenter, i: Key) => center.CityId === props.cityInfo.id
        );
        if (centers.length === 0) {
            setDisplayCenters(false);
        } else {
            setDisplayCenters(true);
        }
    }, [centersState, props.cityInfo.id]);

    return (
        <CentersContainer>
            {errorState ? (
                <Typography variant='h4'>Error</Typography>
            ) : loadingState ? (
                <Typography variant='h4'>LOADING</Typography>
            ) : (
                <>
                    {!displayCenters ? (
                        <TextSmall>No centers added</TextSmall>
                    ) : (
                        <>
                            {centersState
                                .filter(
                                    (center: ICenter, i: Key) =>
                                        center &&
                                        center.CityId === props.cityInfo.id
                                )
                                .map((center: ICenter, i: Key) => (
                                    <CenterCard key={i}>
                                        <Link
                                            to={`/centers/${center.id}`}
                                            key={i}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <TextLink>{center.name}</TextLink>
                                        </Link>
                                        <br />
                                        <TextSmall className='typo'>
                                            {center.address}
                                            <br />
                                            Contact:{' '}
                                            <a
                                                href={center.contactUrl}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'rgb(80, 104, 153)',
                                                }}
                                            >
                                                {center.contactUrl}
                                            </a>
                                            <br />
                                            Booking:{' '}
                                            <a
                                                href={center.bookingUrl}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'rgb(80, 104, 153)',
                                                }}
                                            >
                                                {center.bookingUrl}
                                            </a>
                                            <br />
                                        </TextSmall>
                                    </CenterCard>
                                ))}
                        </>
                    )}
                </>
            )}
        </CentersContainer>
    );
}
