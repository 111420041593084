import { Dispatch } from 'redux';
import { INewsItem } from '../../components/news/News';

export const SET_NEWS = 'SET_NEWS';
export const LOADING_NEWS = 'LOADING_NEWS';
export const ERROR_NEWS = 'ERROR_NEWS';

export interface ISetNewsAction {
    type: typeof SET_NEWS;
    payload: {
        news: INewsItem[];
    };
}

export interface ILoadingAction {
    type: typeof LOADING_NEWS;
    payload: {
        loading: boolean;
    };
}

export interface IErrorAction {
    type: typeof ERROR_NEWS;
    payload: {
        error: boolean;
    };
}

export const setNewsAction = (
    type: string,
    news: INewsItem[],
    dispatch: Dispatch<any>
) => {
    dispatch({
        type: LOADING_NEWS,
        payload: {
            loading: true,
        },
    });

    try {
        dispatch({
            type,
            payload: {
                news,
            },
        });
    } catch (err) {
        dispatch({
            type: ERROR_NEWS,
            payload: {
                error: true,
            },
        });
    }

    dispatch({
        type: LOADING_NEWS,
        payload: {
            loading: false,
        },
    });
};

export type NewsActions = ISetNewsAction | IErrorAction | ILoadingAction;
