import { Button, Dialog, TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDayjs';
import { DatePicker } from '@mui/lab';
import { ChangeEvent, useState } from 'react';
import { INewsItem } from './News';
import {
    ContainerDiv,
    Div,
    FlexColummn,
    Form,
    Image,
    ImageContainer,
    PaddingTB,
    TextLarge,
    MainSection,
} from '../styles';
import { Delete } from './Delete';

interface INewsFormProps {
    id: string;
    newsItem: INewsItem;
    updateNewsItem: (field: string, value: string) => void;
    submit: () => void;
    loadingState: boolean;
    errorState: boolean;
    action: string;
    date: Date | null;
    selectedImage: string | undefined;
    updateDate: (newDate: Date) => void;
    handleImageChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

export const NewsFormUI = (props: INewsFormProps) => {
    const [open, setOpen] = useState(false);
    const imageSrc = props.selectedImage ?? props.newsItem.picture;

    if (props.errorState) {
        return <TextLarge>ERROR</TextLarge>;
    }

    return (
        <MainSection>
            <ContainerDiv>
                <FlexColummn>
                    <PaddingTB>
                        <Form>
                            <TextField
                                className='input'
                                value={props.newsItem.title}
                                multiline
                                label='Title'
                                variant='outlined'
                                onChange={(e) =>
                                    props.updateNewsItem(
                                        'title',
                                        e.target.value
                                    )
                                }
                            />
                            <TextField
                                className='input'
                                value={props.newsItem.text}
                                multiline
                                label='Text'
                                variant='outlined'
                                onChange={(e) =>
                                    props.updateNewsItem('text', e.target.value)
                                }
                            />
                            <TextField
                                className='input'
                                value={props.newsItem.link}
                                multiline
                                label='Link'
                                variant='outlined'
                                onChange={(e) =>
                                    props.updateNewsItem('link', e.target.value)
                                }
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    className='input'
                                    label='Expiration date'
                                    value={props.date}
                                    onChange={(newDate: Date | null | any) => {
                                        const myDate = newDate?.$d!;
                                        props.updateDate(myDate);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                            <PaddingTB>
                                <ImageContainer>
                                    {!props.selectedImage &&
                                    props.newsItem.picture.length < 1 ? (
                                        <TextLarge
                                            style={{ background: '#f2f2f2' }}
                                            className='image'
                                        >
                                            <Button
                                                variant='contained'
                                                component='label'
                                                className='input'
                                            >
                                                Upload Image
                                                <input
                                                    type='file'
                                                    hidden
                                                    onChange={(event) => {
                                                        props.handleImageChange(
                                                            event
                                                        );
                                                    }}
                                                />
                                            </Button>
                                        </TextLarge>
                                    ) : (
                                        <>
                                            <Image
                                                src={imageSrc}
                                                alt='img not found'
                                            />
                                            <Button
                                                variant='contained'
                                                component='label'
                                                className='input'
                                            >
                                                Upload Image
                                                <input
                                                    type='file'
                                                    hidden
                                                    onChange={(event) => {
                                                        props.handleImageChange(
                                                            event
                                                        );
                                                    }}
                                                />
                                            </Button>
                                        </>
                                    )}
                                </ImageContainer>
                            </PaddingTB>
                            <PaddingTB>
                                <Div>
                                    <Button
                                        style={{ marginRight: '1em' }}
                                        variant='outlined'
                                        type='button'
                                        href='/news'
                                    >
                                        Back
                                    </Button>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        {props.action === 'EDIT' ? (
                                            <Button
                                                style={{ marginRight: '1em' }}
                                                variant='outlined'
                                                color='warning'
                                                onClick={() => setOpen(true)}
                                            >
                                                Delete
                                            </Button>
                                        ) : null}

                                        <Dialog
                                            open={open}
                                            onClose={() => setOpen(false)}
                                        >
                                            <Delete
                                                handleOpen={() =>
                                                    setOpen(false)
                                                }
                                            />
                                        </Dialog>

                                        <Button
                                            variant='contained'
                                            type='button'
                                            onClick={() => props.submit()}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Div>
                            </PaddingTB>
                        </Form>
                    </PaddingTB>
                </FlexColummn>
            </ContainerDiv>
        </MainSection>
    );
};
