import { ICity } from '../../components/cities/Cities';
import { CitiesActions } from './citiesAction';
import * as actions from './citiesAction';

export interface IState {
    cities: ICity[];
    loading: boolean;
    error: boolean;
}

export const initialState = {
    cities: [],
    loading: false,
    error: false,
};

export const citiesReducer = (
    state: IState = initialState,
    action: CitiesActions
): IState => {
    switch (action.type) {
        case actions.LOADING_CITIES:
            return (state = {
                ...state,
                loading: action.payload.loading,
            });

        case actions.SET_CITIES:
            return (state = {
                ...state,
                cities: action.payload.cities,
                error: false,
            });

        case actions.ERROR_CITIES:
            return (state = {
                ...state,
                loading: action.payload.error,
            });
        default:
            return state;
    }
};
