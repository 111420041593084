import { AxiosInstance } from 'axios';
import { Dispatch } from 'redux';
import {
    CitiesActions,
    setCitiesAction,
} from '../store/redux-cities/citiesAction';

export const getCities = async (
    api: AxiosInstance,
    dispatch: Dispatch<CitiesActions>
) => {
    try {
        const cities = (await api.get('admin/api/cities')).data;

        return setCitiesAction('SET_CITIES', cities, dispatch);
    } catch (err) {
        alert(err);
    }
};
