import { useCallback, useEffect } from 'react';
import { useApi } from '../../utils/useApi';
import { CentersUI } from './CentersUI';
import { useDispatch, useSelector } from 'react-redux';
import { setCenters } from '../../utils/centersFunctions';
import { selectCenters } from '../../store/selectors';

export interface ICenter {
    id: string;
    name: string;
    address: string;
    picture: string;
    contactUrl: string;
    bookingUrl: string;
    CityId: string;
}

export interface ICity {
    name: string;
    id: string;
}

export const emptyCenter = {
    id: '',
    name: '',
    address: '',
    picture: '',
    contactUrl: '',
    bookingUrl: '',
    CityId: '',
};

export const Centers = () => {
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const api = useApi();
    const centersState = useSelector(selectCenters);

    useEffect(() => {
        setCenters(api, dispatchCallback);
    }, [centersState, api, dispatchCallback]);

    return <CentersUI />;
};
