import { Dispatch } from 'redux';
import { ICity } from '../../components/cities/Cities';

export const SET_CITIES = 'SET_CITIES';
export const LOADING_CITIES = 'LOADING_CITIES';
export const ERROR_CITIES = 'ERROR_CITIES';

export interface ISetCitiesAction {
    type: typeof SET_CITIES;
    payload: {
        cities: ICity[];
    };
}

export interface ILoadingAction {
    type: typeof LOADING_CITIES;
    payload: {
        loading: boolean;
    };
}

export interface IErrorAction {
    type: typeof ERROR_CITIES;
    payload: {
        error: boolean;
    };
}

export const setCitiesAction = (
    type: string,
    cities: ICity[],
    dispatch: Dispatch<any>
) => {
    dispatch({
        type: LOADING_CITIES,
        payload: {
            loading: true,
        },
    });

    try {
        dispatch({
            type,
            payload: {
                cities,
            },
        });
    } catch (err) {
        dispatch({
            type: ERROR_CITIES,
            payload: {
                error: true,
            },
        });
    }

    dispatch({
        type: LOADING_CITIES,
        payload: {
            loading: false,
        },
    });
};

export type CitiesActions = ISetCitiesAction | IErrorAction | ILoadingAction;
