import { TextField, Button } from '@mui/material';
import {
    FlexColummn,
    PaddingTB,
    Form,
    ContainerDiv,
    MainSection,
} from '../styles';

interface ILogInProps {
    setemail: (name: string) => void;
    setPassword: (password: string) => void;
    submit: () => void;
}

export const LogInUI = (props: ILogInProps) => {
    const handleLogin = (e: any) => {
        e.preventDefault();
        props.submit();
    };

    return (
        <MainSection>
            <ContainerDiv>
                <FlexColummn>
                    <PaddingTB>
                        <Form onSubmit={handleLogin}>
                            <TextField
                                className='input'
                                multiline
                                label='Email'
                                variant='outlined'
                                onChange={(e) => props.setemail(e.target.value)}
                            />
                            <TextField
                                className='input'
                                label='Password'
                                type='password'
                                variant='outlined'
                                onChange={(e) =>
                                    props.setPassword(e.target.value)
                                }
                            />
                            <PaddingTB>
                                <Button
                                    typeof='button'
                                    variant='outlined'
                                    type='button'
                                    onClick={props.submit}
                                >
                                    Log In
                                </Button>
                            </PaddingTB>
                        </Form>
                    </PaddingTB>
                </FlexColummn>
            </ContainerDiv>
        </MainSection>
    );
};
