import { ICenter } from '../../components/centers/Centers';
import * as actions from './centersAction';
import { CentersActions } from './centersAction';

export interface IState {
    centers: ICenter[];
    loading: boolean;
    error: boolean;
}

export const initialState = {
    centers: [],
    loading: false,
    error: false,
};

export const centersReducer = (
    state: IState = initialState,
    action: CentersActions
): IState => {
    switch (action.type) {
        case actions.LOADING_CENTERS:
            return (state = {
                ...state,
                loading: action.payload.loading,
            });

        case actions.SET_CENTERS:
            return (state = {
                ...state,
                centers: action.payload.centers,
                error: false,
            });

        case actions.ERROR_CENTERS:
            return (state = {
                ...state,
                loading: action.payload.error,
            });
        default:
            return state;
    }
};
