import React, { Key, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from '../../utils/useApi';
import { CitiesUI } from '../cities/CitiesUI';
import { Link } from 'react-router-dom';
import { CitiesCard, TextSmall } from '../styles';
import {
    selectError,
    selectLoading,
    selectCities,
} from './../../store/selectors';
import { Typography } from '@mui/material';
import { setCenters } from '../../utils/centersFunctions';
import { getCities } from '../../utils/citiesFunctions';

export interface ICity {
    id: null;
    name: string;
    createdAt: null;
    updatedAt: null;
}

export const emptyCityItem: ICity = {
    id: null,
    name: '',
    createdAt: null,
    updatedAt: null,
};

export const Cities: React.FC = () => {
    const api = useApi();
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const citiesState = useSelector(selectCities);
    const errorState = useSelector(selectError);
    const loadingState = useSelector(selectLoading);

    const [input, setInput] = useState<string>('');

    useEffect(() => {
        getCities(api, dispatchCallback);
        setCenters(api, dispatchCallback);
    }, [citiesState, dispatchCallback, api]);

    const handleSubmit = async () => {
        if (!input) return;

        const city = citiesState?.find((c) => c.name === input);

        if (!city) {
            const newCity = {
                name: input,
            };

            try {
                const response = (await api.post('admin/api/city', newCity))
                    .data;
                alert(response);
                setInput('');
            } catch (err: any) {
                alert(err.message);
            }
        } else {
            return alert('City exists');
        }
    };

    const citiesList =
        citiesState !== null
            ? citiesState.map((city: ICity, i: Key) => {
                  return (
                      <Link
                          to={`/cities/${city.id}`}
                          key={i}
                          style={{ textDecoration: 'none' }}
                      >
                          <CitiesCard>
                              <TextSmall className='typo'>
                                  {city.name}
                              </TextSmall>
                          </CitiesCard>
                      </Link>
                  );
              })
            : [];

    return (
        <>
            {errorState ? (
                <Typography variant='h4'>Error</Typography>
            ) : loadingState ? (
                <Typography variant='h4'>LOADING</Typography>
            ) : (
                <CitiesUI
                    citiesArray={citiesList}
                    input={input}
                    setState={setInput}
                    addCity={handleSubmit}
                />
            )}
        </>
    );
};
