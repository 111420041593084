import { Typography } from '@mui/material';
import { Key, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    selectError,
    selectLoading,
    selectNews,
} from './../../store/selectors';
import { setNews } from './../../utils/newsFunctions';
import { NewsUI } from './NewsUI';
import AddIcon from '@mui/icons-material/Add';
import {
    ContainerDiv,
    FloatingButtonContainer,
    Image,
    ImageContainer,
    MainSection,
    NewsCard,
    TextLarge,
    TextSmall,
    TypographyContainer,
} from '../styles';
import { useApi } from '../../utils/useApi';

export interface INewsItem {
    id: string;
    title: string;
    text: string;
    picture: string;
    link: string;
    expireDate: string;
}

export const emptyNewsItem: INewsItem = {
    id: '',
    title: '',
    text: '',
    picture: '',
    link: '',
    expireDate: '',
};

export const News = () => {
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const newsState = useSelector(selectNews);
    const errorState = useSelector(selectError);
    const loadingState = useSelector(selectLoading);
    const api = useApi();

    useEffect(() => {
        setNews(api, dispatchCallback);
    }, [dispatchCallback, api]);

    const newsList =
        newsState.length > 0
            ? newsState.map((item: INewsItem, i: Key) => {
                  return (
                      <NewsCard key={i} className='grid-item'>
                          <Link
                              to={`/news/${item.id}`}
                              style={{ textDecoration: 'none' }}
                          >
                              <ImageContainer>
                                  <Image src={item.picture} alt={item.title} />
                              </ImageContainer>
                              <TypographyContainer>
                                  <TextLarge className='typo'>
                                      {item.title}
                                  </TextLarge>
                                  <TextSmall className='typo'>
                                      {item.text}
                                  </TextSmall>
                              </TypographyContainer>
                          </Link>
                      </NewsCard>
                  );
              })
            : [];

    return (
        <MainSection>
            <ContainerDiv className='grid-container'>
                {errorState ? (
                    <Typography variant='h4'>ERROR</Typography>
                ) : loadingState ? (
                    <Typography variant='h4'>LOADING</Typography>
                ) : (
                    <NewsUI newsArray={newsList} />
                )}
                <Link to={'/news/add'} aria-label='add'>
                    <FloatingButtonContainer>
                        <AddIcon className='FloatingButton' />
                    </FloatingButtonContainer>
                </Link>
            </ContainerDiv>
        </MainSection>
    );
};
