import { Dispatch } from 'redux';
import { ICenter } from '../../components/centers/Centers';

export const SET_CENTERS = 'SET_CENTERS';
export const LOADING_CENTERS = 'LOADING_CENTERS';
export const ERROR_CENTERS = 'ERROR_CENTERS';

export interface ISetCentersAction {
    type: typeof SET_CENTERS;
    payload: {
        centers: ICenter[];
    };
}

export interface ILoadingAction {
    type: typeof LOADING_CENTERS;
    payload: {
        loading: boolean;
    };
}

export interface IErrorAction {
    type: typeof ERROR_CENTERS;
    payload: {
        error: boolean;
    };
}

export const setCentersAction = (
    type: string,
    centers: ICenter[],
    dispatch: Dispatch<any>
) => {
    dispatch({
        type: LOADING_CENTERS,
        payload: {
            loading: true,
        },
    });

    try {
        dispatch({
            type,
            payload: {
                centers,
            },
        });
    } catch (err) {
        dispatch({
            type: ERROR_CENTERS,
            payload: {
                error: true,
            },
        });
    }

    dispatch({
        type: LOADING_CENTERS,
        payload: {
            loading: false,
        },
    });
};

export type CentersActions = ISetCentersAction | IErrorAction | ILoadingAction;
