interface INewsProps {
  newsArray: JSX.Element[]
}

export const NewsUI = (props: INewsProps) => {
  return (
    <>
      {props.newsArray}
    </>
  )
}
