import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCenters } from '../../store/selectors';
import { setCenters } from '../../utils/centersFunctions';
import { useApi } from '../../utils/useApi';
import { ICenter, emptyCenter } from './Centers';
import EditCenterUI from './EditCenterUI';

export default function EditCenters() {
    type Param = { id: string };
    const { id } = useParams<Param>();
    const centersState = useSelector(selectCenters);
    const api = useApi();
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);

    const [center, setCenter] = useState<ICenter>(emptyCenter);

    useEffect(() => {
        if (!centersState || centersState.length < 1) {
            setCenters(api, dispatchCallback);
            return;
        }

        const news = centersState.find((n) => n.id === id);
        if (news) {
            setCenter(news);
        }
    }, [id, centersState, dispatchCallback, api]);

    return <EditCenterUI center={center} action='EDIT' />;
}
