import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useApi } from '../../utils/useApi';
import { setCitiesAction } from '../../store/redux-cities/citiesAction';
import { selectCities } from './../../store/selectors';
import { ICity } from './Cities';

interface IDeleteProps {
    handleOpen: () => void;
}

export const Delete = (props: IDeleteProps) => {
    const dispatch = useDispatch();
    const citiesState = useSelector(selectCities);
    const api = useApi();
    type Param = { id: string };
    const { id } = useParams<Param>();

    const [city, setCity] = useState<ICity>();

    useEffect(() => {
        citiesState.map((city) => {
            if (city.id === id) {
                return setCity(city);
            }
            return null;
        });
    }, [id, city, citiesState]);

    const deleteItem = async () => {
        await api.delete(`admin/api/city/${id}`);

        const stateCopy = citiesState;
        const itemIndex = stateCopy.findIndex((city: ICity) => city.id === id);
        stateCopy.splice(itemIndex, 1);
        setCitiesAction('SET_CITIES', stateCopy, dispatch);

        window.history.back();
    };

    return (
        <DialogContent>
            <DialogTitle>
                Are you sure you want to delete this city?
            </DialogTitle>
            <DialogActions>
                <Button onClick={props.handleOpen}>Cancel</Button>
                <Button
                    onClick={deleteItem}
                    variant='contained'
                    color='warning'
                >
                    Delete
                </Button>
            </DialogActions>
        </DialogContent>
    );
};
