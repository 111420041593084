import { ChangeEvent, useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    selectError,
    selectLoading,
    selectNews,
} from './../../store/selectors';
import { emptyNewsItem, INewsItem } from './News';
import { setNews, updateNews } from './../../utils/newsFunctions';
import { NewsFormUI } from './NewsForm';
import { dateToUnix } from './../../utils/timeFunctions';
import { useApi } from '../../utils/useApi';
import axios from 'axios';

export const EditForm = () => {
    const newsState = useSelector(selectNews);
    const errorState = useSelector(selectError);
    const loadingState = useSelector(selectLoading);
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const api = useApi();
    const history = useHistory();

    type Param = { id: string };
    const { id } = useParams<Param>();

    const [newsItem, setNewsItem] = useState<INewsItem>(emptyNewsItem);
    const [date, setDate] = useState<Date | null>(null);
    const [selectedImage, setSelectedImage] = useState<string | undefined>();

    useEffect(() => {
        if (!newsState || newsState.length < 1) {
            setNews(api, dispatchCallback);
            return;
        }

        const news = newsState.find((n) => n.id === id);
        if (news) {
            setNewsItem(news);
        }
    }, [id, newsState, dispatchCallback, api]);

    const updateNewsItem = (field: string, value: string) => {
        setNewsItem((prev) => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const updateDate = (newDate: Date) => {
        setDate(newDate);
        updateNewsItem('expireDate', dateToUnix(newDate));
    };

    const submit = async () => {
        updateNews(newsState, newsItem, dispatch);

        const { title, text, picture, link, expireDate } = newsItem;
        const newsObject = { title, text, picture, link, expireDate };

        try {
            const res = (
                await api.put(
                    `admin/api/offer/${newsItem.id}`,
                    JSON.stringify(newsObject)
                )
            ).status;
            if (res === 200) {
                history.push('/news');
            }
        } catch (err) {
            alert(err);
        }
    };

    const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }
        const file = event.target.files![0];
        const res = (
            await api.post('admin/api/gcs', {
                extention: file.name.split('.')[1],
            })
        ).data;
        updateNewsItem('picture', res[0].split('?').shift());

        try {
            await axios.put(res[0], file, {
                headers: { 'Content-Type': file.type },
            });
        } catch (err) {
            alert(err);
        }
    };

    return (
        <NewsFormUI
            action='EDIT'
            newsItem={newsItem}
            updateNewsItem={updateNewsItem}
            id={id}
            submit={submit}
            loadingState={loadingState}
            errorState={errorState}
            date={date}
            updateDate={updateDate}
            handleImageChange={handleImageChange}
            selectedImage={selectedImage}
        />
    );
};
