import { INewsItem } from '../../components/news/News';
import { NewsActions } from './newsAction';
import * as actions from './newsAction';

export interface IState {
    news: INewsItem[];
    loading: boolean;
    error: boolean;
}

export const initialState = {
    news: [],
    loading: false,
    error: false,
};

export const newsReducer = (
    state: IState = initialState,
    action: NewsActions
): IState => {
    switch (action.type) {
        case actions.LOADING_NEWS:
            return (state = {
                ...state,
                loading: action.payload.loading,
            });

        case actions.SET_NEWS:
            return (state = {
                ...state,
                news: action.payload.news,
                error: false,
            });

        case actions.ERROR_NEWS:
            return (state = {
                ...state,
                loading: action.payload.error,
            });
        default:
            return state;
    }
};
