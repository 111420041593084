import {
    createStore,
    combineReducers,
    applyMiddleware,
    Middleware,
    compose,
} from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createReduxEnhancer as sentryReduxEnhancer } from '../sentry';
import { centersReducer } from './redux-centers/centersReducer';
import { citiesReducer } from './redux-cities/citiesReducer';
import { newsReducer } from './redux-news/newsReducer';

const rootReducer = combineReducers({
    newsReducer: newsReducer,
    citiesReducer: citiesReducer,
    centersReducer: centersReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
    const middlewares: Middleware[] = [thunk];
    const middleWareEnhancer = applyMiddleware(...middlewares);

    const store = createStore(
        rootReducer,
        compose(
            middleWareEnhancer,
            sentryReduxEnhancer(),
            devToolsEnhancer({
                trace: true,
            })
        )
    );

    return store;
}
