export const dateToUnix = (date: Date): string => {
  if (!date) return "";

  return JSON.stringify(Math.floor(date.getTime() / 1000));
};

export const unixToDate = (unix: string): Date => {
  const date = new Date(parseInt(unix) * 1000);
  return date;
};
