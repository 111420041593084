import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export interface IPrivateRoute { }

const PrivateRoute: React.FunctionComponent<IPrivateRoute> = props => {
    const { children } = props;
    const auth = useAuth()
    console.log(auth.user)

    if (auth.user){
        return <>{children}</>
    }

    return (
        <Redirect to="/" />
    );
}

export default PrivateRoute;