import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectCities } from './../../store/selectors';
import { emptyCityItem, ICity } from './Cities';
import EditCityUI from './EditCityUI';
import { useApi } from '../../utils/useApi';
import { getCities } from '../../utils/citiesFunctions';

export const EditCity = () => {
    const citiesState = useSelector(selectCities);
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const api = useApi();
    type Param = { id: string };
    const { id } = useParams<Param>();

    const [cityItem, setCityItem] = useState<ICity>(emptyCityItem);

    useEffect(() => {
        if (!citiesState || citiesState.length < 1) {
            getCities(api, dispatchCallback);
            return;
        }

        const city = citiesState.find((c) => c.id === id);
        if (city) {
            setCityItem(city);
        }
    }, [id, citiesState, api, dispatchCallback]);

    return <EditCityUI city={cityItem} action='EDIT' />;
};
