import styled from 'styled-components';

// GENERAL
export const Body = styled.div`
    margin: 0;
`;

export const MainSection = styled.section`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: rgb(244, 244, 244);
    margin: 0;
    padding: 45px 0 0 0;
`;

export const ContainerDiv = styled.div`
    padding: 1em;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    margin: 0;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-gap: 1rem;
`;

export const Container = styled.div`
    width: 20em;
    max-width: 90vw;
    margin: 2em;
    padding: 2em;
    justify-self: center;
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    border-radius: 1em;
    overflow: hidden;
    box-shadow: 0 0 8px lightgray;
`;

export const Div = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const PaddingTB = styled.div`
    padding: 1em 0;
`;

export const FlexColummn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: min-content;
    margin-left: auto;
    margin-right: auto;
`;

export const FlexDiv = styled.div`
    width: 80%;
    margin: 30px auto 30px auto;
    display: flex;
    justify-content: space-between;
`;

export const Article = styled.div`
    width: 80%;
    margin: 0 auto;
`;

// TEXT
export const TypographyContainer = styled.div`
    height: 10em;
    padding: 1em;
    display: flex;
    flex-direction: column;

    .typo {
        font-size: 1em;
        margin-bottom: 1em;
    }
`;

export const TextSmall = styled.span`
    font-family: 'Saira Condensed', Arial sans-serif;
    color: black;
    letter-spacing: 0.6px;
    line-height: 1.5;
    font-size: 1.2em;
`;

export const TextLink = styled(TextSmall)`
    font-weight: bold;
    font-size: 1.3em;
    letter-spacing: 1px;

    &:hover {
        color: rgb(80, 104, 153);
    }
`;

export const TextLarge = styled(TextSmall)`
    font-weight: bold;
    font-size: 1.3em;
    letter-spacing: 1px;
`;

export const TextHeader = styled(TextSmall)`
    text-align: center;
    font-size: 1.8em;
    font-weight: bolder;
    margin-bottom: 15px;
    width: 18em;
`;

// CARDS
export const NewsCard = styled.div`
    width: 20em;
    max-width: 90vw;
    margin: 2em;
    justify-self: center;
    display: flex;
    flex-direction: column;
    color: black;
    background-color: white;
    border-radius: 1em;
    overflow: hidden;
    box-shadow: 0 0 8px lightgray;
`;

export const CitiesCard = styled.div`
    width: 25em;
    max-width: 90vw;
    margin: 1em auto;
    padding: 0.8em;
    color: black;
    background-color: rgb(255, 255, 255);
    border-radius: 0.8em;
    overflow: hidden;
    box-shadow: 0 0 3px lightgray;
    border: 1px solid rgba(255, 255, 255, 0);

    &:hover {
        border: 1px solid lightgray;
    }
`;

export const CenterCard = styled.div`
    width: 20em;
    margin: 10px 20px;
    padding: 1.5em;
    color: black;
    background-color: rgb(255, 255, 255);
    border-radius: 0.8em;
    box-shadow: 0 0 3px lightgray;
    word-wrap: break-word;
`;

export const CenterInfo = styled.div`
    width: 25em;
    max-width: 90vw;
    margin: 1em auto;
    padding: 0.8em;
    color: black;
    background-color: rgb(255, 255, 255);
    border-radius: 0.8em;
    overflow: hidden;
    box-shadow: 0 0 3px lightgray;
`;

// CONTAINERS
export const CentersContainer = styled.div`
    width: 90%;
    margin: 30px auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

export const SearchContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

// BUTTON
export const FloatingButtonContainer = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1.5em;
    bottom: 1.5em;
    height: 3.5em;
    width: 3.5em;
    background-color: rgb(80, 104, 153);
    border-radius: 50%;
    box-shadow: 2px 2px 8px gray;

    &:hover {
        background-color: black;
    }

    .FloatingButton {
        text-decoration: none;
        color: white;
        font-size: 2.5em;
    }
`;

// FORMS
export const CENTER_FORM = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 22em;
    max-width: 90vw;

    .button {
        align-self: flex-end;
        width: max-content;
    }

    .input {
        margin-bottom: 0.5em;
    }

    .image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const CITY_FORM = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25em;
    max-width: 90vw;

    .button {
        align-self: flex-end;
        width: max-content;
    }

    .input {
        margin-bottom: 0.5em;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 22em;
    max-width: 90vw;

    .input {
        margin-bottom: 0.5em;
    }

    .image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const SearchForm = styled.div`
    width: 80%;
    margin: 30px auto 30px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    font-family: 'Saira Condensed', Arial sans-serif;
    border-bottom: 1px solid lightgray;
`;

// IMAGE
export const Image = styled.img`
    width: auto;
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

export const ImageContainer = styled.div`
    height: 10em;
    width: 100%;
    display: flex;
    margin-bottom: 1em;
    justify-content: space-between;
    align-content: space-between;
    flex-direction: column;
    margin-bottom: 50px;
`;
