import { useEffect, useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import { useAuth } from '../../contexts/AuthContext';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';

const HEADER = styled.header`
    background-color: rgb(80, 104, 153);
    position: fixed;
    z-index: 100;
    height: 60px;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 10px;
    letter-spacing: 0.6px;
    font-size: 1.3em;
    font-family: 'Saira Condensed', Arial sans-serif;

    .logout {
        cursor: pointer;
        text-decoration: none;
        color: white;
        padding: 1em 0.5em;

        &.active {
            color: rgb(240, 254, 111);
        }

        &:hover {
            color: rgb(240, 254, 111);
        }
    }
`;

const HEADER_ITEM = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    color: white;
    padding: 1em 0.5em;

    &.active {
        color: rgb(240, 254, 111);
    }

    &:hover {
        color: rgb(240, 254, 111);
    }
`;

export const Header = () => {
    const location = useLocation();
    const history = useHistory();
    const [locationState, setLocationState] = useState(location.pathname);
    const authContext = useAuth();

    useEffect(() => {
        setLocationState(location.pathname);
    }, [location.pathname]);

    const logout = async () => {
        try {
            await signOut(auth);
            history.push('/');
        } catch (err) {
            alert(err);
        }
    };

    if (!authContext.user) {
        return <HEADER></HEADER>;
    }

    return (
        <HEADER>
            <HEADER_ITEM
                className={locationState.includes('news') ? 'active' : ''}
                to='/news'
            >
                NEWS
            </HEADER_ITEM>
            <HEADER_ITEM
                className={locationState.includes('/cities') ? 'active' : ''}
                to='/cities'
            >
                CITIES
            </HEADER_ITEM>
            <HEADER_ITEM
                className={locationState.includes('/centers') ? 'active' : ''}
                to='/centers'
            >
                CENTERS
            </HEADER_ITEM>
            <FontAwesomeIcon
                onClick={logout}
                className='logout'
                icon={faArrowRightToBracket}
            />
        </HEADER>
    );
};
