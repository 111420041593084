import { useState } from 'react';
import { Button, Dialog } from '@mui/material';
import {
    MainSection,
    Article,
    TextHeader,
    TextLarge,
    SearchForm,
} from '../styles';
import { ICity } from './Cities';
import { Delete } from '../cities/Delete';
import CityCenters from './CityCenters';
import { useSelector } from 'react-redux';
import { selectCenters } from '../../store/selectors';

interface IEditCityProps {
    city: ICity;
    action: string;
}

export default function EditCityUI(props: IEditCityProps) {
    const centersState = useSelector(selectCenters);

    const [open, setOpen] = useState(false);

    const handleDelete = () => {
        let emptyCity = [];
        centersState.map((center) =>
            center.CityId === props.city.id ? emptyCity.push(center.CityId) : ''
        );

        if (emptyCity.length > 0) {
            alert('Delete all centers in this city to be able to delete city.');
            return;
        }
        setOpen(true);
    };

    return (
        <MainSection>
            <SearchForm>
                <TextHeader>{props.city.name}</TextHeader>
                <div style={{ width: '25em', marginBottom: '15px' }}>
                    <Button
                        style={{ marginRight: '1em' }}
                        variant='outlined'
                        type='button'
                        href='/cities'
                    >
                        Back
                    </Button>
                    {props.action === 'EDIT' ? (
                        <Button
                            style={{ marginRight: '1em' }}
                            variant='outlined'
                            color='warning'
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                    ) : null}

                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <Delete handleOpen={() => setOpen(false)} />
                    </Dialog>
                </div>
            </SearchForm>

            <Article>
                <TextLarge>All centers in {props.city.name}:</TextLarge>
            </Article>
            <CityCenters cityInfo={props.city} />
        </MainSection>
    );
}
