import { Autocomplete, TextField } from '@mui/material';
import { Key, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectCenters } from '../../store/selectors';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
    CentersContainer,
    CenterCard,
    TextSmall,
    MainSection,
    SearchForm,
    TextHeader,
    TextLink,
    FloatingButtonContainer,
} from '../styles';
import { ICenter } from './Centers';

export const CentersUI = () => {
    const history = useHistory();
    const centersState = useSelector(selectCenters);

    const [searchCenter, setSearchCenter] = useState<String | null>();

    return (
        <MainSection>
            <SearchForm>
                <TextHeader>All centers</TextHeader>
                <form
                    onSubmit={(e) => {
                        history.push(`/centers/${searchCenter}`);
                        e.preventDefault();
                    }}
                    style={{ width: '25em', marginBottom: '15px' }}
                >
                    <Autocomplete
                        id='free-solo-2-demo'
                        freeSolo
                        disableClearable
                        options={centersState.map((center) => center.name)}
                        onChange={(event, newValue) => {
                            const center: ICenter | undefined =
                                centersState?.find(
                                    (center) => center.name === newValue
                                );
                            center && setSearchCenter(center.id);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Search center'
                                variant='outlined'
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                </form>
            </SearchForm>
            <CentersContainer>
                {centersState.map((center: ICenter, i: Key) => (
                    <CenterCard key={i}>
                        <Link
                            to={`/centers/${center.id}`}
                            key={i}
                            style={{ textDecoration: 'none' }}
                        >
                            <TextLink>{center.name}</TextLink>
                        </Link>
                        <br />
                        <TextSmall className='typo'>
                            {center.address}
                            <br />
                            Contact:{' '}
                            <a
                                href={center.contactUrl}
                                target='_blank'
                                rel='noreferrer'
                                style={{
                                    textDecoration: 'none',
                                    color: 'rgb(80, 104, 153)',
                                }}
                            >
                                {center.contactUrl}
                            </a>
                            <br />
                            Booking:{' '}
                            <a
                                href={center.bookingUrl}
                                style={{
                                    textDecoration: 'none',
                                    color: 'rgb(80, 104, 153)',
                                }}
                            >
                                {center.bookingUrl}
                            </a>
                            <br />
                        </TextSmall>
                    </CenterCard>
                ))}
            </CentersContainer>

            <Link to={'/centers/add'} aria-label='add'>
                <FloatingButtonContainer>
                    <AddIcon className='FloatingButton' />
                </FloatingButtonContainer>
            </Link>
        </MainSection>
    );
};
