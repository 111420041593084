import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useApi } from '../../utils/useApi';
import { setNewsAction } from '../../store/redux-news/newsAction';
import { selectNews } from './../../store/selectors';
import { setNews } from './../../utils/newsFunctions';
import { INewsItem } from './News';

interface IDeleteProps {
    handleOpen: () => void;
}

export const Delete = (props: IDeleteProps) => {
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const newsState = useSelector(selectNews);
    const api = useApi();
    type Param = { id: string };
    const { id } = useParams<Param>();

    const [item, setItem] = useState<INewsItem>();

    useEffect(() => {
        setNews(api, dispatchCallback);
    }, [dispatchCallback, api]);

    useEffect(() => {
        newsState.map((myItem) => {
            if (myItem.id === id) {
                return setItem(myItem);
            }
            return null;
        });
    }, [id, item, newsState]);

    const deleteItem = async () => {
        await api.delete(`admin/api/offer/${id}`);

        const stateCopy = newsState;
        const itemIndex = stateCopy.findIndex(
            (item: INewsItem) => item.id === id
        );
        stateCopy.splice(itemIndex, 1);
        setNewsAction('SET_NEWS', stateCopy, dispatch);

        window.history.back();
    };

    return (
        <DialogContent>
            <DialogTitle>
                Are you sure you want to delete this item?
            </DialogTitle>
            <DialogActions>
                <Button onClick={props.handleOpen}>Cancel</Button>
                <Button
                    onClick={deleteItem}
                    variant='contained'
                    color='warning'
                >
                    Delete
                </Button>
            </DialogActions>
        </DialogContent>
    );
};
