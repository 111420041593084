import axios from 'axios';
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectNews, selectError, selectLoading } from '../../store/selectors';
import { updateNews } from '../../utils/newsFunctions';
import { dateToUnix } from '../../utils/timeFunctions';
import { useApi } from '../../utils/useApi';
import { emptyNewsItem, INewsItem } from './News';
import { NewsFormUI } from './NewsForm';

export const AddForm = () => {
    const api = useApi();
    const dispatch = useDispatch();
    const newsState = useSelector(selectNews);
    const errorState = useSelector(selectError);
    const loadingState = useSelector(selectLoading);

    const [newsItem, setNewsItem] = useState<INewsItem>(emptyNewsItem);
    const [date, setDate] = useState<Date | null>(null);
    const [selectedImage, setSelectedImage] = useState<string | undefined>();

    type Param = { id: string };
    const { id } = useParams<Param>();

    const updateNewsItem = (field: string, value: string) => {
        setNewsItem((prev) => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const updateDate = (newDate: Date) => {
        setDate(newDate);
        updateNewsItem('expireDate', dateToUnix(newDate));
    };

    const submit = async () => {
        updateNews(newsState, newsItem, dispatch);

        const { title, text, picture, link, expireDate } = newsItem;
        const newsObject = { title, text, picture, link, expireDate };

        try {
            await api.post('admin/api/offer', newsObject);
        } catch (err: any) {
            alert(err.message);
        }

        window.history.back();
    };

    const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }
        const file = event.target.files![0];
        const res = (
            await api.post('admin/api/gcs', {
                extention: file.name.split('.')[1],
            })
        ).data;
        updateNewsItem('picture', res[0].split('?').shift());

        try {
            await axios.put(res[0], file, {
                headers: { 'Content-Type': file.type },
            });
        } catch (err) {
            alert(err);
        }
    };

    return (
        <NewsFormUI
            action='ADD'
            newsItem={newsItem}
            updateNewsItem={updateNewsItem}
            id={id}
            submit={submit}
            loadingState={loadingState}
            errorState={errorState}
            date={date}
            updateDate={updateDate}
            handleImageChange={handleImageChange}
            selectedImage={selectedImage}
        />
    );
};
