import { useState } from 'react';
import { LogInUI } from './LogInUI';
import { auth } from './../../utils/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

export const LogIn = () => {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submit = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password).then(() => {
                history.push('/news');
            });
        } catch (err) {
            alert(err);
        }
    };

    return (
        <LogInUI
            setemail={setEmail}
            setPassword={setPassword}
            submit={submit}
        />
    );
};
