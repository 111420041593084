import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useApi } from '../../utils/useApi';
import { selectCenters } from './../../store/selectors';
import { ICenter } from './Centers';
import { setCentersAction } from '../../store/redux-centers/centersAction';
import { setCenters } from '../../utils/centersFunctions';

interface IDeleteProps {
    handleOpen: () => void;
}

export const Delete = (props: IDeleteProps) => {
    type Param = { id: string };
    const { id } = useParams<Param>();
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const centersState = useSelector(selectCenters);
    const api = useApi();

    const [center, setCenter] = useState<ICenter>();

    useEffect(() => {
        setCenters(api, dispatchCallback);
    }, [centersState, dispatchCallback, api]);

    useEffect(() => {
        centersState.map((center) => {
            if (center.id === id) {
                return setCenter(center);
            }
            return null;
        });
    }, [id, center, centersState]);

    const deleteItem = async () => {
        await api.delete(`admin/api/center/${id}`);

        const stateCopy = centersState;
        const itemIndex = stateCopy.findIndex(
            (center: ICenter) => center.id === id
        );
        stateCopy.splice(itemIndex, 1);
        setCentersAction('SET_CENTERS', stateCopy, dispatch);

        window.history.back();
    };

    return (
        <DialogContent>
            <DialogTitle>
                Are you sure you want to delete this center?
            </DialogTitle>
            <DialogActions>
                <Button onClick={props.handleOpen}>Cancel</Button>
                <Button
                    onClick={deleteItem}
                    variant='contained'
                    color='warning'
                >
                    Delete
                </Button>
            </DialogActions>
        </DialogContent>
    );
};
