import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { News } from './components/news/News';
import { EditForm } from './components/news/EditForm';
import { AddForm } from './components/news/AddfForm';
import { LogIn } from './components/login/LogIn';
import { Header } from './components/core-components/Header';
import { Centers } from './components/centers/Centers';
import { Cities } from './components/cities/Cities';
import { AuthProvider } from './../src/contexts/AuthProvider';
import PrivateRoute from './components/PrivateRoute';
import { EditCity } from './components/cities/EditCity';
import EditCenters from './components/centers/EditCenters';
import NewCenter from './components/centers/NewCenter';

function App() {
    return (
        <div className='App'>
            <header className='App-header'>
                <AuthProvider>
                    <Router>
                        <Header />
                        <Switch>
                            <Route exact path='/' component={LogIn} />
                            <Route path='/news/add'>
                                <PrivateRoute>
                                    <AddForm />
                                </PrivateRoute>
                            </Route>
                            <Route path='/news/:id'>
                                <PrivateRoute>
                                    <EditForm />
                                </PrivateRoute>
                            </Route>
                            <Route path='/news'>
                                <PrivateRoute>
                                    <News />
                                </PrivateRoute>
                            </Route>
                            <Route path='/cities/:id'>
                                <PrivateRoute>
                                    <EditCity />
                                </PrivateRoute>
                            </Route>
                            <Route path='/cities'>
                                <PrivateRoute>
                                    <Cities />
                                </PrivateRoute>
                            </Route>
                            <Route path='/centers/add'>
                                <PrivateRoute>
                                    <NewCenter />
                                </PrivateRoute>
                            </Route>
                            <Route path='/centers/:id'>
                                <PrivateRoute>
                                    <EditCenters />
                                </PrivateRoute>
                            </Route>
                            <Route path='/centers'>
                                <PrivateRoute>
                                    <Centers />
                                </PrivateRoute>
                            </Route>
                            <Route path='*'>404</Route>
                            {/* <Route exact path="/" component={LogIn} />
              <PrivateRoute path="/news" component={News}/>
              <PrivateRoute path="/news/add" component={AddForm} />
              <PrivateRoute path="/news/:id" component={EditForm} />
              <PrivateRoute path="/cities" component={Cities} />
              <PrivateRoute path="/centers" component={Centers} />
              <Route path="*">404</Route> */}
                        </Switch>
                    </Router>
                </AuthProvider>
            </header>
        </div>
    );
}

export default App;
