import { Autocomplete, Button, TextField } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useApi } from '../../utils/useApi';
import { selectCenters } from '../../store/selectors';
import { useHistory } from 'react-router-dom';
import { emptyCenter, ICity } from './Centers';
import { ICenter } from './Centers';

import {
    ImageContainer,
    TextLarge,
    Image,
    FlexColummn,
    PaddingTB,
    CENTER_FORM,
    MainSection,
    SearchForm,
    TextHeader,
} from '../styles';

export default function NewCenter() {
    const api = useApi();
    let history = useHistory();
    const dispatch = useDispatch();
    const dispatchCallback = useCallback(dispatch, [dispatch]);
    const [validInput, setValidInput] = useState<boolean>(false);
    const centersState = useSelector(selectCenters);

    const [centerState, setCenterState] = useState<ICenter>(emptyCenter);
    const [cities, setCities] = useState<ICity[] | null>(null);

    const getCities = useCallback(async () => {
        try {
            const cities = (await api.get('admin/api/cities')).data;
            setCities(cities);
        } catch (err) {
            alert(err);
        }
    }, [api]);

    useEffect(() => {
        getCities();
    }, [getCities, centersState, api, dispatchCallback]);

    useEffect(() => {
        const { name, address, contactUrl, bookingUrl, CityId } = centerState;
        if (
            name === '' ||
            address === '' ||
            contactUrl === '' ||
            bookingUrl === '' ||
            CityId === ''
        ) {
            setValidInput(false);
        } else {
            setValidInput(true);
        }
    }, [centerState]);

    const handleInput = (id: string, value: string) => {
        setCenterState((prev) => {
            return {
                ...prev,
                [id]: value,
            };
        });
    };

    const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const url = URL.createObjectURL(event.target.files[0]);
            setCenterState((prev) => {
                return {
                    ...prev,
                    picture: url,
                };
            });
        }
    };

    const submit = async () => {
        const { name, address, contactUrl, bookingUrl, CityId, picture } =
            centerState;
        if (!name || !address || !contactUrl || !bookingUrl || !CityId) {
            return alert('Provide all info');
        }

        const sendState = {
            name,
            address,
            contactUrl,
            bookingUrl,
            CityId,
            picture,
        };

        try {
            const response = (await api.post('admin/api/center', sendState))
                .data;
            alert(response);
            history.push('/centers');
        } catch (err: any) {
            alert(err.message);
        }
    };

    return (
        <MainSection>
            <SearchForm>
                <TextHeader>Add center</TextHeader>
                <div style={{ width: '25em', marginBottom: '15px' }}>
                    <Button
                        style={{ marginRight: '1em' }}
                        variant='outlined'
                        type='button'
                        href='/centers'
                    >
                        Back
                    </Button>
                </div>
            </SearchForm>

            <FlexColummn>
                <PaddingTB>
                    <CENTER_FORM>
                        <TextField
                            className='input'
                            multiline
                            label='Name'
                            variant='outlined'
                            onChange={(e) =>
                                handleInput('name', e.target.value)
                            }
                        />
                        <TextField
                            className='input'
                            multiline
                            label='Adress'
                            variant='outlined'
                            onChange={(e) =>
                                handleInput('address', e.target.value)
                            }
                        />
                        {cities && (
                            <Autocomplete
                                id='auto-select'
                                options={cities.map((city) => {
                                    return city.name;
                                })}
                                autoSelect
                                className='input'
                                onChange={(event, newValue) => {
                                    const city: ICity | undefined =
                                        cities?.find(
                                            (city) => city.name === newValue
                                        );
                                    city && handleInput('CityId', city.id);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='City'
                                        variant='outlined'
                                    />
                                )}
                            />
                        )}
                        <TextField
                            className='input'
                            multiline
                            label='Contact URL'
                            variant='outlined'
                            onChange={(e) =>
                                handleInput('contactUrl', e.target.value)
                            }
                        />
                        <TextField
                            className='input'
                            multiline
                            label='Booking URL'
                            variant='outlined'
                            onChange={(e) =>
                                handleInput('bookingUrl', e.target.value)
                            }
                        />
                        <ImageContainer>
                            {!centerState.picture ||
                            centerState.picture.length < 1 ? (
                                <TextLarge
                                    style={{ background: '#f2f2f2' }}
                                    className='image'
                                >
                                    <Button
                                        variant='contained'
                                        component='label'
                                        className='input'
                                    >
                                        Upload Image
                                        <input
                                            type='file'
                                            hidden
                                            onChange={(event) => {
                                                handleImageChange(event);
                                            }}
                                        />
                                    </Button>
                                </TextLarge>
                            ) : (
                                <>
                                    <Image
                                        src={centerState.picture}
                                        alt='img not found'
                                    />
                                    <Button
                                        variant='contained'
                                        component='label'
                                        className='input'
                                    >
                                        Upload Image
                                        <input
                                            type='file'
                                            hidden
                                            onChange={(event) => {
                                                handleImageChange(event);
                                            }}
                                        />
                                    </Button>
                                </>
                            )}
                        </ImageContainer>
                        <Button
                            className='button'
                            variant='contained'
                            disabled={!validInput}
                            type='button'
                            onClick={submit}
                        >
                            Save
                        </Button>
                    </CENTER_FORM>
                </PaddingTB>
            </FlexColummn>
        </MainSection>
    );
}
