import Autocomplete from '@mui/material/Autocomplete';
import { Button, TextField } from '@mui/material';
import {
    FlexColummn,
    MainSection,
    PaddingTB,
    TextHeader,
    SearchForm,
    CITY_FORM,
} from '../styles';
import { selectCities } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ICity } from './Cities';
import { useHistory } from 'react-router-dom';

interface ICitiesProps {
    citiesArray: JSX.Element[];
    setState: (value: string) => void;
    input: string | undefined;
    addCity: () => void;
}

export const CitiesUI = (props: ICitiesProps) => {
    const citiesState = useSelector(selectCities);
    const history = useHistory();

    const [searchCity, setSearchCity] = useState<String | null>();

    return (
        <MainSection>
            <SearchForm>
                <TextHeader>All cities</TextHeader>
                <form
                    onSubmit={(e) => {
                        history.push(`/cities/${searchCity}`);
                        e.preventDefault();
                    }}
                    style={{ width: '25em', marginBottom: '15px' }}
                >
                    <Autocomplete
                        id='free-solo-2-demo'
                        freeSolo
                        disableClearable
                        options={citiesState.map((city) => city.name)}
                        onChange={(event, newValue) => {
                            const city: ICity | undefined = citiesState?.find(
                                (city) => city.name === newValue
                            );
                            city && setSearchCity(city.id);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Search city'
                                variant='outlined'
                                type='search'
                                style={{ width: '25em' }}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                </form>
            </SearchForm>
            {props.citiesArray}
            <FlexColummn>
                <PaddingTB>
                    <CITY_FORM>
                        <TextField
                            className='input'
                            label='City'
                            value={props.input}
                            variant='outlined'
                            onChange={(e) => props.setState(e.target.value)}
                        />
                        <Button
                            className='button'
                            variant='contained'
                            disabled={!props.input}
                            type='button'
                            onClick={props.addCity}
                        >
                            Add city
                        </Button>
                    </CITY_FORM>
                </PaddingTB>
            </FlexColummn>
        </MainSection>
    );
};
