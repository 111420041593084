import axios from "axios"
import { useMemo } from "react";
import { useAuth } from "../contexts/AuthContext";

export const useApi = () => {

    const auth = useAuth()
    const client = useMemo(() => {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${auth.token}`
              },
        })
    }, [auth])

    return client
}