
import { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { User } from "firebase/auth";
import { auth } from "./../utils/firebase";

export const AuthProvider: React.FC = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)
    const [token, setToken] = useState('')

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user)

            if(user) {
                user.getIdToken().then(token => {
                    setToken(token)
                    setLoading(false)
                })
            } else {
                setToken('')
                setLoading(false)
            }
        });

        return unsubscribe;
    }, []);

    return <AuthContext.Provider value={{user: currentUser, token}}>{!loading && children}</AuthContext.Provider>;
};