import { AxiosInstance } from 'axios';
import { Dispatch } from 'redux';
import { INewsItem } from '../components/news/News';
import { NewsActions, setNewsAction } from '../store/redux-news/newsAction';

export const setNews = async (
    api: AxiosInstance,
    dispatch: Dispatch<NewsActions>
) => {
    try {
        const news = (await api.get<INewsItem[]>('admin/api/offers')).data;
        return setNewsAction('SET_NEWS', news, dispatch);
    } catch (err) {
        console.log(err);
    }
};

export const updateNews = (
    state: INewsItem[],
    newItem: INewsItem,
    dispatch: Dispatch<NewsActions>
) => {
    let updatedNews: INewsItem[] = state;
    const itemIndex = state.findIndex(
        (item: INewsItem) => item.id === newItem.id
    );
    updatedNews[itemIndex] = newItem;
    setNewsAction('SET_NEWS', updatedNews, dispatch);
};
