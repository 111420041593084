import { AxiosInstance } from 'axios';
import { Dispatch } from 'redux';
import {
    CentersActions,
    setCentersAction,
} from '../store/redux-centers/centersAction';
import { ICenter } from '../components/centers/Centers';

export const setCenters = async (
    api: AxiosInstance,
    dispatch: Dispatch<CentersActions>
) => {
    try {
        const centers = (await api.get<ICenter[]>('admin/api/centers')).data;

        return setCentersAction('SET_CENTERS', centers, dispatch);
    } catch (err) {
        console.log(err);
    }
};
